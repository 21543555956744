import {
  Avatar,
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Col,
  Container,
  Row,
} from 'darrius';

import Link from 'next/link';

import { MdKeyboardArrowRight } from 'react-icons/md';

import { getInfoToAuxiliaryPages } from '../../../../modules/orders/pageProps/getInfoToAuxiliaryPages/getInfoToAuxiliaryPages';

import { Status } from '../../../../components';

import { OrderCategoryTitle } from '../OrderCategoryTitle/OrderCategoryTitle';

import { type IOrdersPageProps } from '../../../../modules/orders/pageProps/@interfaces';

import { triggerEvent } from '../../../../utils';

import styles from './OrderCard.module.scss';

interface IOrderCardProps {
  order: IOrdersPageProps;
}

export function OrderCard({ order }: IOrderCardProps) {
  const hasAuxiliaryInfoPage =
    order.status == 'finalized_zero_lead' || order.status === 'blocked';

  const handleTriggerEventClickCard = () => {
    triggerEvent('order-card__click-card', {
      requestUuid: order.uuid,
      orderId: order.id,
    });
  };

  return (
    <Container as="li" className={styles.orders}>
      <Row className="start-md start-sm">
        <Col className="col-sm-12 col-md-12">
          <time dateTime={order.createdAt} className={styles.orders__date}>
            {order.orderDate}
          </time>

          <Card className={styles.orders__card}>
            <CardHeader>
              <div className={styles.orders__card_header}>
                <OrderCategoryTitle
                  rootCategory={{
                    text: order.rootCategory,
                  }}
                  category={{
                    text: order.category,
                  }}
                />

                <Link
                  href={`/meus-pedidos/${order.uuid}`}
                  onClick={handleTriggerEventClickCard}
                >
                  <MdKeyboardArrowRight size={24} />
                </Link>
              </div>

              <hr className={styles.orders__card_header__line} />
            </CardHeader>

            <CardBody className={styles.orders__card_body}>
              <Status.Root>
                <Status.Title
                  status={order.hasNotification ? 'notification' : order.status}
                  remainingHours={order.remainingHours}
                  as="h3"
                />

                <Status.Description
                  status={order.hasNotification ? 'notification' : order.status}
                  totalProfessionalsFound={order.totalProfessionalsFound}
                />
              </Status.Root>

              {order.leads.length > 0 && (
                <ul className={styles.orders__profiles}>
                  {order.leads.map((lead) => (
                    <li key={lead.id}>
                      <Avatar
                        src={lead.avatarUrl}
                        alt=""
                        size={58}
                        notification={lead.notifications}
                        fallback={{
                          variant: 'byName',
                          name: lead.name,
                        }}
                      />
                    </li>
                  ))}
                </ul>
              )}
            </CardBody>

            {!!hasAuxiliaryInfoPage && (
              <CardFooter>
                <hr className={styles.orders__card_footer__line} />
                <Link
                  href={getInfoToAuxiliaryPages(order.status).href}
                  passHref
                  legacyBehavior
                >
                  <Button.Link variation="clear">
                    {getInfoToAuxiliaryPages(order.status).text}
                  </Button.Link>
                </Link>
              </CardFooter>
            )}
          </Card>
        </Col>
      </Row>
    </Container>
  );
}

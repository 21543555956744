import { handleErrorResponse } from '../error/handleErrorResponse';

import { axiosInstance } from '../utils/axiosInstance';
import { applyCustomHeaders } from '../utils/server/applyCustomHeaders';

const ERROR_SERVICE_NAME_PREFIX = '[FUKIYA]';

export const fukiyaService = async <T = unknown>(
  path: string,
  query?: unknown,
  customHeaders?: Record<string, string>
): Promise<T> => {
  try {
    const headers = {};

    applyCustomHeaders({ headers, customHeaders, keys: ['x-request-id'] });

    const { data } = await axiosInstance<T>({
      baseURL: process.env.FUKIYA_ENDPOINT,
      url: path,
      params: query,
      headers,
    });

    return data;
  } catch (error) {
    if (error.response.status === 404) {
      return null;
    }

    throw handleErrorResponse({
      error,
      prefix: ERROR_SERVICE_NAME_PREFIX,
    });
  }
};

import { Button, Col, Container, Row, Typography } from 'darrius';

import rootStyles from '../../OrderProFormTemplate.module.scss';
import styles from './OrderProFormOptionsStep.module.scss';
import { type TReason } from '../../../../modules/orderProForm/requests/@interfaces';

interface IOrderProFormOptionsStepProps {
  reasons: TReason[];
  reasonOption: string;
  isSending: boolean;
  onSubmit: () => void;
  onChange: (reason: string) => void;
}

function OrderProFormOptionsStep({
  reasons,
  reasonOption,
  isSending,
  onChange,
  onSubmit,
}: IOrderProFormOptionsStepProps) {
  const actionButtonText =
    reasonOption === 'Outro motivo' ? 'Próximo' : 'Finalizar';

  const isActionButtonDisabled = !reasonOption;

  return (
    <Container>
      <Row
        className={`space-top col-sm-12 col-md-12 ${rootStyles.container__content}`}
      >
        <Col>
          <Typography.Subtitle as="h1" size="l">
            O que te motivou a encerrar a negociação?
          </Typography.Subtitle>
        </Col>
      </Row>

      <Row
        className={`space-top col-sm-12 col-md-12 ${rootStyles.container__content}`}
      >
        <Col>
          <div className={styles.selector}>
            {reasons.map((reason) => (
              <label
                key={reason.reason}
                className={
                  reasonOption === reason.label ? styles.selector__active : ''
                }
              >
                <input
                  type="radio"
                  name="reason"
                  value={reason.label}
                  onChange={() => onChange(reason.label)}
                />
                <span>{reason.label}</span>
              </label>
            ))}
          </div>
        </Col>
      </Row>

      <Row
        className={`space-top space-bottom  ${rootStyles.container__action}`}
      >
        <Col className="col-md-6">
          <Button.Root
            fullWidth
            disabled={isActionButtonDisabled}
            onClick={onSubmit}
            isLoading={isSending}
          >
            {actionButtonText}
          </Button.Root>
        </Col>
      </Row>
    </Container>
  );
}

export { OrderProFormOptionsStep };

import { type TAuthUser } from '../../pages/api/auth/login';
import { bffService } from '../../services';
import { decryptByAES } from '../../utils/api/secret';

import { getDataRequestsDenied, userIsNotAuthorized } from './validations';

export type TAuthRequest = {
  cookies: Partial<{
    [key: string]: string;
  }>;
};

export interface IUserCliDataResponse {
  id: string;
  name: string | null;
  phone: string | null;
  avatar: string | null;
  isAuthenticated: boolean;
  notificationCount: number;
}

export const AUTH_TOKEN = 'gn-auth:token_v2';
export const AUTH_USER = 'gn-auth:user_v3';

export const AUTH_SECRET = process.env.AUTH_SECRET;

function getData(request: TAuthRequest, userId?: string | number) {
  const userCookie = request.cookies[AUTH_USER];

  if (getDataRequestsDenied(userCookie)) {
    return {
      userData: null,
    };
  }

  const decryptUser = decryptByAES({
    ciphertext: userCookie,
    secretKey: AUTH_SECRET,
  });

  const userData = JSON.parse(decryptUser);

  const userIdParsed = userId ? String(userId) : null;

  if (
    userIsNotAuthorized({ userId: userIdParsed, userIdDecrypted: userData.id })
  ) {
    return {
      userData: null,
    };
  }

  return {
    userData: JSON.parse(decryptUser) as TAuthUser,
  };
}

function getToken(request: TAuthRequest) {
  const tokenCookie = request.cookies[AUTH_TOKEN];

  if (getDataRequestsDenied(tokenCookie)) {
    return {
      token: null,
    };
  }

  const decryptUser = decryptByAES({
    ciphertext: tokenCookie,
    secretKey: AUTH_SECRET,
  });

  return {
    token: decryptUser,
  };
}

function parseUserData(
  userData: Omit<IUserCliDataResponse, 'isAuthenticated'> | null
): IUserCliDataResponse {
  if (!userData) {
    return {
      id: null,
      avatar: null,
      name: null,
      phone: null,
      isAuthenticated: false,
      notificationCount: 0,
    };
  }

  return {
    ...userData,
    isAuthenticated: true,
  };
}

interface IConversationUnreadApiResponse {
  user_id: string;
  unread_messages: number;
}

async function getNotifications(request: TAuthRequest) {
  const { token } = getToken(request);

  if (!token) {
    return {
      status: 'error',
      notificationCount: 0,
    };
  }

  try {
    const messageData = await bffService<
      unknown,
      IConversationUnreadApiResponse
    >({
      path: 'message_broker/conversations/unread',
      method: 'GET',
      version: 1,
      useCache: false,
      customHeaders: {
        Authorization: token,
      },
    });

    return {
      status: 'success',
      notificationCount: messageData.data.unread_messages,
    };
  } catch (error) {
    return {
      status: 'error',
      notificationCount: 0,
    };
  }
}

export const user = {
  getData,
  getToken,
  parseUserData,
  getNotifications,
};

const DAY_IN_MILISSECONDS = 24 * 60 * 60 * 1000;

function get(cookieName: string) {
  const cookies = document.cookie.split(';');

  for (let i = 0; i < cookies.length; i++) {
    const cookie = cookies[i].trim();

    if (cookie.startsWith(cookieName + '=')) {
      return cookie.substring(cookieName.length + 1, cookie.length);
    }
  }

  return null;
}

function set(name: string, value: string, days: number) {
  const date = new Date();

  date.setTime(date.getTime() + days * DAY_IN_MILISSECONDS);

  const expires = 'expires=' + date.toUTCString();

  document.cookie = name + '=' + value + ';' + expires + ';path=/';
}

export const clientCookie = {
  get,
  set,
};

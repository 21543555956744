import zod from 'zod';

import { AUTH_SESSION_FEATURE_IS_ENABLED } from '../../../utils/featureFlags/authSession';

import { SECRET_PREFIX } from '../../../utils/api/secret';

const tokenContract = zod.object({
  cookie: zod.string().startsWith(SECRET_PREFIX),
});

export type TTokenContract = zod.infer<typeof tokenContract>;

interface ICheckUserCredentialsParams {
  userIdDecrypted: string;
  userId?: string;
}

function cookieContractIsInvalid({ cookie }: TTokenContract) {
  const { success } = tokenContract.safeParse({ cookie });

  return !success;
}

function isFeatureFlagDisabled() {
  return !AUTH_SESSION_FEATURE_IS_ENABLED;
}

export function userIsNotAuthorized({
  userId = null,
  userIdDecrypted,
}: ICheckUserCredentialsParams) {
  if (userId) {
    if (userIdDecrypted !== userId) {
      return true;
    }
  }

  return false;
}

export function getDataRequestsDenied(cookieValue: string) {
  if (!cookieValue) {
    return true;
  }

  if (cookieContractIsInvalid({ cookie: cookieValue })) {
    return true;
  }

  if (process.env.NODE_ENV === 'production') {
    if (isFeatureFlagDisabled()) {
      return true;
    }
  }

  return false;
}

import CryptoJS from 'crypto-js';

// TODO: Esse arquivo inteiro ser global pra haver a comunicação entre Site-Clientes e Site-Pro

export const SECRET_PREFIX = 'GN_';

interface IEncryptByAES {
  value: string;
  secretKey: string;
}

interface IDecryptByAES {
  ciphertext: string;
  secretKey: string;
}

function encryptByAES({ secretKey, value }: IEncryptByAES) {
  const key = CryptoJS.enc.Utf8.parse(secretKey);

  const encrypted = CryptoJS.AES.encrypt(value, key, {
    mode: CryptoJS.mode.ECB,
    padding: CryptoJS.pad.Pkcs7,
  });

  return `${SECRET_PREFIX}${encrypted.toString()}`;
}

function decryptByAES({ ciphertext, secretKey }: IDecryptByAES): string {
  const ciphertextWithoutPrefix = ciphertext.replace(SECRET_PREFIX, '');

  const key = CryptoJS.enc.Utf8.parse(secretKey);

  const decrypted = CryptoJS.AES.decrypt(ciphertextWithoutPrefix, key, {
    mode: CryptoJS.mode.ECB,
    padding: CryptoJS.pad.Pkcs7,
  });

  return decrypted.toString(CryptoJS.enc.Utf8);
}

export { encryptByAES, decryptByAES };

import { useState } from 'react';

import dynamic from 'next/dynamic';
import Image from 'next/image';

import { Container, Typography } from 'darrius';

import { OrderMessageDialog } from '../OrderMessageDialog';

import { Dialog, ProCardDetail } from '../../../../../../components';

import { imgPrefix, navigate, triggerEvent } from '../../../../../../utils';

import styles from './FoundProfessionalsList.module.scss';

import { type TOrderDetailLeads } from '../../../../../../modules/orderDetails/transformations/@interfaces';

const OrderRatingCard = dynamic(
  () =>
    import('../OrderRatingCard/OrderRatingCard').then(
      (mod) => mod.OrderRatingCard
    ),
  {
    ssr: false,
  }
);

interface IFoundProfessionalsListProps {
  reviewUrl: string | null;
  leads: TOrderDetailLeads[];
  uuid: string;
}

export function FoundProfessionalsList({
  reviewUrl,
  leads,
  uuid,
}: IFoundProfessionalsListProps) {
  const [isOrderMessageDialogOpen, setIsOrderMessageDialogOpen] =
    useState(false);
  const [selectedPro, setSelectedPro] = useState<TOrderDetailLeads>(
    {} as TOrderDetailLeads
  );

  function handleSelectPro(data: TOrderDetailLeads) {
    triggerEvent('chat-cli__show-message-button', {
      requestUuid: uuid,
      profileId: data.id,
    });

    if (data.chatLink) {
      navigate(data.chatLink);
    } else {
      setSelectedPro(data);
      setIsOrderMessageDialogOpen(true);
    }
  }

  function handleToggleDialog() {
    setIsOrderMessageDialogOpen((prev) => !prev);
  }

  return (
    <Dialog.Root
      open={isOrderMessageDialogOpen}
      onOpenChange={handleToggleDialog}
    >
      <Container className={styles.container}>
        <div className={styles.content}>
          {!!reviewUrl && <OrderRatingCard link={reviewUrl} />}

          <div className={styles.content__title}>
            <Image
              src={imgPrefix('/images/orders/pro.svg')}
              alt=""
              width={48}
              height={48}
            />

            <Typography.Subtitle as="h5" size="l">
              Profissionais encontrados
            </Typography.Subtitle>
          </div>

          <ul className={styles.list}>
            {leads.map((lead, index) => {
              if (lead.isPartnerProfile) {
                return (
                  <li key={lead.id}>
                    <ProCardDetail.Institutional
                      id={lead.id}
                      leadId={lead.leadId}
                      isVerifiedPro={lead.documentationApproved}
                      link={lead.link}
                      name={lead.name}
                      photo={lead.avatar}
                      servicePrice={lead.price}
                      shouldShowChatButton={lead.shouldShowChatButton}
                      onMessageClick={handleSelectPro}
                      rating={{
                        count: lead.reviews.count,
                        value: lead.reviews.average,
                      }}
                    />
                  </li>
                );
              } else {
                return (
                  <>
                    <li key={lead.id}>
                      <ProCardDetail.Default
                        isDisabled={lead.isRejected}
                        id={lead.id}
                        leadId={lead.leadId}
                        chatLink={lead.chatLink}
                        initialMessage={lead.initialMessage}
                        index={index}
                        link={lead.link}
                        name={lead.name}
                        photo={lead.avatar}
                        isVerifiedPro={lead.documentationApproved}
                        shouldShowChatButton={lead.shouldShowChatButton}
                        onMessageClick={handleSelectPro}
                        acceptedConversation={lead.acceptedConversation}
                        rating={{
                          count: lead.reviews.count,
                          value: lead.reviews.average,
                        }}
                      />
                    </li>
                    <OrderMessageDialog
                      pro={selectedPro}
                      orderId={uuid}
                      handleToggleDialog={handleToggleDialog}
                      initialMessage={lead.initialMessage}
                      chatUrl={lead.chatLink}
                      avatar={lead.avatar}
                      uuid={uuid}
                    />
                  </>
                );
              }
            })}
          </ul>
        </div>
      </Container>
    </Dialog.Root>
  );
}

import {
  AUTH_USER,
  AUTH_TOKEN,
  type IUserCliDataResponse,
} from '../modules/user';
import { type Maybe } from '../types/utils';

import { clientCookie } from './clientCookie';
import { decryptByAES } from './secret';

type TFetchSessionRepository = Maybe<{
  authorization: string;
  userData: IUserCliDataResponse;
}>;

function fetchSession(): TFetchSessionRepository | null {
  const tokenValue = clientCookie.get(AUTH_TOKEN);
  const userValue = clientCookie.get(AUTH_USER);

  if (!tokenValue || !userValue) {
    return null;
  }

  const authorization = decryptByAES({
    ciphertext: tokenValue,
    secretKey: process.env.NEXT_PUBLIC_AUTH_SECRET!,
  });

  const user = decryptByAES({
    ciphertext: userValue,
    secretKey: process.env.NEXT_PUBLIC_AUTH_SECRET!,
  });

  const userData = JSON.parse(user) as IUserCliDataResponse;

  return { authorization, userData };
}

export { fetchSession };

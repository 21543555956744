import privacyPolicy from '../data/shared/privacy-policy.json';

import { handleErrorResponse } from '../error/handleErrorResponse';

import { axiosInstance } from '../utils/axiosInstance';
import { applyCustomHeaders } from '../utils/server/applyCustomHeaders';

interface IAgreementsApiResponse {
  data: {
    attributes: {
      contents: string;
    };
  };
}

const ERROR_SERVICE_NAME_PREFIX = '[AGREEMENTS-SERVICE]';

export const agreementsService = async (
  type: string,
  version: string,
  customHeaders?: Record<string, unknown>
): Promise<string> => {
  if (type === 'site') {
    return privacyPolicy.contents;
  }

  const listTypes = {
    clientes: 'cli/cli-agreements',
    profissionais: 'pro/pro-agreements',
  };

  const urlType = listTypes[type];

  const headers = {};

  applyCustomHeaders({ headers, customHeaders, keys: ['x-request-id'] });

  try {
    const res = await axiosInstance<IAgreementsApiResponse>({
      baseURL: process.env.AGREEMENTS_ENDPOINT,
      url: `v1/${urlType}/${version}`,
      headers,
    });

    return res.data.data.attributes.contents;
  } catch (error) {
    const prefix = `${ERROR_SERVICE_NAME_PREFIX} - [Type: ${type} | Version: ${version}]`;

    throw handleErrorResponse({
      error,
      prefix,
    });
  }
};

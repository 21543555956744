import { locales } from '../locales';

import { applyModifiers } from '../utils';

interface IModifier {
  searchValue: string;
  replaceValue: string | number;
}

export const localizedContentService = <T = unknown>(
  locale: string,
  include: string[],
  modifiers?: IModifier[]
): T => {
  let content = {};

  if (!include || include.length === 0) {
    return null;
  }

  const selectedLocale = findCorrectLocale(locale);

  if (!selectedLocale) {
    return null;
  }

  include.forEach((item) => {
    content[item] = locales[selectedLocale][item];
  });

  if (modifiers) {
    content = applyModifiers(content, modifiers);
  }

  return content as T;
};

// If locale comes without country (pt, es, en), returns first option with substring (pt-BR, es-MX, en-US), necessary for local testing
function findCorrectLocale(locale) {
  return Object.keys(locales).find((l) => l.includes(locale));
}

import { type AxiosRequestConfig } from 'axios';

import { AppError } from '../error/AppError';

import { axiosInstance } from '../utils/axiosInstance';
import { applyCustomHeaders } from '../utils/server/applyCustomHeaders';

interface ISeoDataService {
  slugs: string[] | string;
  customHeaders?: Record<string, string>;
}

const ERROR_SERVICE_NAME_PREFIX = '[SEO-DATA-SSERVICE]';

export const seoDataService = async <T>({
  slugs: slugParams,
  customHeaders,
}: ISeoDataService): Promise<T> => {
  const slugs = Array.isArray(slugParams) ? slugParams : [slugParams];

  validateParams();

  const headers = {};

  applyCustomHeaders({ headers, customHeaders, keys: ['x-request-id'] });

  const requestOptions: AxiosRequestConfig = {
    headers,
    url: `${process.env.SEO_DATABASE_URL}${getPath()}`,
    method: 'GET',
  };

  try {
    const { data } = await axiosInstance(requestOptions);

    return data as T;
  } catch (error) {
    return null;
  }

  function validateParams() {
    const validSlugRegex = new RegExp(/^[a-zA-Z|0-9|'|/|-]+$/);

    const invalidSlugs = slugs.filter((slug) => !validSlugRegex.test(slug));

    if (invalidSlugs.length > 0) {
      throw new AppError(
        ERROR_SERVICE_NAME_PREFIX,
        404,
        `The following slugs has an invalid format: ${invalidSlugs.join(', ')}`,
        true
      );
    }
  }

  function getPath() {
    const endpoint = Array.isArray(slugParams) ? 'mget' : 'get';
    const values = slugs.map((slug) => `br:${slug}`).join(',');

    return `/kv/?${endpoint}=${values}`;
  }
};

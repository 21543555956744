// eslint-disable-next-line import/no-unresolved
import { BudgetTemplate } from './Budget/BudgetTemplate';
import { CategoryTemplate } from './Category/CategoryTemplate';
import { ChatForConciergeTemplate } from './Chat/ChatForConciergeTemplate';
import { ChatTemplate } from './Chat/ChatTemplate';
import { ChatWebviewTemplate } from './Chat/ChatWebviewTemplate';
import { FaqArticleTemplate } from './Faq/FaqArticleTemplate';
import { FaqHomeTemplate } from './Faq/FaqHomeTemplate';
import { FaqDeleteAccountTemplate } from './Faq/FaqDeleteAccount';
import { HomeTemplate } from './Home/HomeTemplate';
import { InstantMatchTemplate } from './InstantMatch/InstantMatchTemplate';
import { NotFoundTemplate } from './NotFound/NotFoundTemplate';
import { UnderConstructionTemplate } from './UnderConstruction/UnderConstructionTemplate';
import { PageDownTemplate } from './PageDown/PageDownTemplate';
import { AgreementsTemplate } from './Agreements/AgreementsTemplate';
import { PriceTemplate } from './Price/PriceTemplate';
import { SurveyTemplate } from './Survey/SurveyTemplate';
import { PhoneVerificationTemplate } from './PhoneVerification/PhoneVerificationTemplate';
import { SitemapTemplate } from './Sitemap/SitemapTemplate';
import { RequestTemplate } from './Request/RequestTemplate';
import { DuplicatedRequestTemplate } from './DuplicatedRequest/DuplicatedRequestTemplate';
import { ErrorTemplate } from './ErrorTemplate/ErrorTemplate';
import { AuthTemplate } from './Auth/AuthTemplate';
import { SignInTemplate } from './SignIn/SignInTemplate';
import { OrdersTemplate } from './Orders/OrdersTemplate';
import { ProsNotFoundOrderTemplate } from './Orders/ProsNotFoundOrderTemplate';
import { BlockedOrderTemplate } from './Orders/BlockedOrderTemplate';
import { OrderDetailsTemplate } from './Orders/OrderDetailsTemplate';
import { OrderProFormTemplate } from './Orders/OrderProFormTemplate';

export {
  BudgetTemplate,
  CategoryTemplate,
  ChatForConciergeTemplate,
  ChatTemplate,
  ChatWebviewTemplate,
  FaqArticleTemplate,
  FaqHomeTemplate,
  FaqDeleteAccountTemplate,
  HomeTemplate,
  InstantMatchTemplate,
  NotFoundTemplate,
  PriceTemplate,
  AgreementsTemplate,
  SurveyTemplate,
  PhoneVerificationTemplate,
  SitemapTemplate,
  RequestTemplate,
  DuplicatedRequestTemplate,
  UnderConstructionTemplate,
  PageDownTemplate,
  ErrorTemplate,
  AuthTemplate,
  SignInTemplate,
  OrdersTemplate,
  ProsNotFoundOrderTemplate,
  BlockedOrderTemplate,
  OrderDetailsTemplate,
  OrderProFormTemplate,
};

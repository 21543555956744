import { AxiosError, type AxiosResponse } from 'axios';
import { AppError } from './AppError';

interface IHandleErrorResponseParams {
  /**
   * Error to be thrown.
   */
  error: unknown;
  /**
   * Prefix to be added to the error message.
   */
  prefix: string;
  /**
   * If the error is operational or not.
   */
  isOperational?: boolean;
}

const INTERNAL_SERVER_ERROR_STATUS = 500;
const GATEWAY_TIMEOUT_STATUS = 504;

export function handleErrorResponse({
  error,
  prefix,
  isOperational = false,
}: IHandleErrorResponseParams) {
  if (error instanceof AxiosError) {
    let status = INTERNAL_SERVER_ERROR_STATUS;

    if (error.response != null) {
      status = (error.response as AxiosResponse).status;
    } else if (error.request != null) {
      status = GATEWAY_TIMEOUT_STATUS;
    }

    return new AppError(prefix, status, error.message, isOperational);
  }

  return new AppError(
    prefix,
    INTERNAL_SERVER_ERROR_STATUS,
    String(error),
    false
  );
}

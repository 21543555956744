import averagePrices from './averagePrices.json';
import budgets from './budgets.json';
import ctaPro from './ctaPro.json';
import faq from './faq.json';
import mainSection from './mainSection.json';
import ratings from './ratings.json';
import relatedLinks from './relatedLinks.json';
import similarRequests from './similarRequests.json';
import form from './form.json';

export const category = {
  averagePrices,
  budgets,
  ctaPro,
  faq,
  mainSection,
  ratings,
  relatedLinks,
  similarRequests,
  form,
};

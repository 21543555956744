import { Button, Col, Container, Row, Textarea, Typography } from 'darrius';

import rootStyles from '../../OrderProFormTemplate.module.scss';
import styles from './OrderProFormOtherTextStep.module.scss';

interface IOrderProFormOptionsStepProps {
  otherReasonValue: string;
  isSending: boolean;
  onSetOtherReasonValue: (text: string) => void;
  handleBackStep: () => void;
  onSubmit: () => void;
}

function OrderProFormOtherTextStep({
  otherReasonValue,
  isSending,
  onSetOtherReasonValue,
  onSubmit,
  handleBackStep,
}: IOrderProFormOptionsStepProps) {
  const otherReasonValueLength = 250 - otherReasonValue.length;

  return (
    <Container>
      <Row
        className={`space-top col-sm-12 col-md-12 ${rootStyles.container__content}`}
      >
        <Col>
          <Typography.Subtitle as="h1" size="l">
            Outro motivo
          </Typography.Subtitle>

          <Typography.Text as="p">
            Conta pra gente qual é motivo de recusar o contato do profissional.
            Isso nos ajuda a melhorar sua experiência com o GetNinjas.
          </Typography.Text>
        </Col>
      </Row>

      <Row
        className={`space-top col-sm-12 col-md-12 ${rootStyles.container__content}`}
      >
        <Col>
          <Typography.Text as="p">Opcional</Typography.Text>
          <div className={styles.textarea}>
            <Textarea
              rows={10}
              placeholder="Digite aqui o motivo caso deseje."
              value={otherReasonValue}
              onChange={(e) => onSetOtherReasonValue(e.target.value)}
              maxLength={250}
            />

            <span>{otherReasonValueLength}</span>
          </div>
        </Col>
      </Row>

      <Row className={`space-top space-bottom ${rootStyles.container__action}`}>
        <Col className={`col-md-6 ${rootStyles.container__action_row}`}>
          <Button.Root fullWidth variation="secondary" onClick={handleBackStep}>
            Voltar
          </Button.Root>

          <Button.Root fullWidth onClick={onSubmit} isLoading={isSending}>
            Finalizar
          </Button.Root>
        </Col>
      </Row>
    </Container>
  );
}

export { OrderProFormOtherTextStep };

interface IapplyCustomHeadersParams {
  headers: unknown;
  customHeaders: unknown;
  keys: string[];
}

export function applyCustomHeaders({
  headers,
  customHeaders,
  keys,
}: IapplyCustomHeadersParams) {
  if (customHeaders) {
    keys.forEach((key) => {
      if (customHeaders[key]) {
        headers[key] = customHeaders[key] as string;
      }
    });
  }
}

import hero from './hero.json';

import { faqCallToAction, meta, mostSearchedServices } from '../shared/request';

export const duplicatedRequest = {
  meta,
  hero,
  mostSearchedServices,
  faqCallToAction,
};

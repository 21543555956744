import { useState } from 'react';

import { Container } from 'darrius';
import { FaArrowLeft } from 'react-icons/fa';

import { navigate, getFlashTitle, triggerEvent } from '../../utils';
import { errorPrefix } from '../../utils/errorPrefix';

import { notifyErrorClient } from '../../services/notifyError/client';

import { showToast } from '../../components/atoms/Toast/Toast';

import { HeadContent, Header, Toast } from '../../components';

import { OrderProFormOptionsStep } from './components/OrderProForm/OrderProFormOptionsStep';
import { OrderProFormOtherTextStep } from './components/OrderProForm/OrderProFormOtherTextStep';

import styles from './OrderProFormTemplate.module.scss';

import { type IOrderProfissionalFormProps } from '../../pages/meus-pedidos/[uuid]/profissional/[id]';
import { fetchSession } from '../../utils/fetchSession';
import { axiosInstance } from '../../utils/axiosInstance';
import { type AxiosRequestConfig } from 'axios';

function OrderProFormTemplate({
  userData,
  leadId,
  reasons,
  uuid,
  profileId,
  rejectEndpoint,
}: IOrderProfissionalFormProps) {
  const [step, setStep] = useState(0);
  const [reasonOption, setReasonOption] = useState('');
  const [otherReasonValue, setOtherReasonValue] = useState('');
  const [isSending, setIsSending] = useState(false);

  const flashTitle = getFlashTitle(userData.notificationCount || 0);

  function getReason() {
    if (otherReasonValue && step === 1) {
      return `${reasonOption}: ${otherReasonValue}`;
    }

    return reasonOption;
  }

  async function onSubmitForm() {
    const reason = getReason();

    const data = {
      reason,
      lead_id: leadId,
    };

    try {
      setIsSending(true);

      const session = fetchSession();
      if (!session) {
        return;
      }

      const headers = {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        'Accept-Version': 7,
        Authorization: session.authorization,
      };

      const requestOptions: AxiosRequestConfig = {
        headers,
        url: rejectEndpoint,
        method: 'POST',
        data,
      };

      await axiosInstance(requestOptions, false).then(() => {
        navigate(`/meus-pedidos/${uuid}`);
      });
    } catch (error) {
      notifyErrorClient(errorPrefix('[ORDER-PRO-FORM]', error));

      showToast({
        type: 'error',
        message: 'Erro ao enviar formulário. Por favor, tente novamente.',
      });

      setIsSending(false);
    }
  }

  function handleChange(reason) {
    setReasonOption(reason);
    triggerEvent('chat-cli__refuse-reason', {
      reason,
      requestUuid: uuid,
      profileId,
    });
  }

  function handleSubmit() {
    const isOtherOption = reasonOption === 'Outro motivo';

    if (isOtherOption) {
      const isLastStep = step === 1;

      if (isLastStep) {
        onSubmitForm();

        return;
      }

      setStep(1);

      return;
    }

    onSubmitForm();
  }

  function handleBackStep() {
    setStep(0);
  }

  function renderStep() {
    if (step === 0) {
      return (
        <OrderProFormOptionsStep
          reasonOption={reasonOption}
          isSending={isSending}
          onSubmit={handleSubmit}
          onChange={handleChange}
          reasons={reasons}
        />
      );
    }

    return (
      <OrderProFormOtherTextStep
        otherReasonValue={otherReasonValue}
        isSending={isSending}
        onSetOtherReasonValue={setOtherReasonValue}
        handleBackStep={handleBackStep}
        onSubmit={handleSubmit}
      />
    );
  }

  return (
    <>
      <Header.Auth userData={userData} />

      <HeadContent
        flashTitle={flashTitle}
        metaTitle="Encerrar negociação | GetNinjas"
        metaDescription="Encerre a negociação com o profissional e nos conte o motivo."
        pageType="order-pro-form:show"
        noindex
      />

      <Container>
        <div>
          <button
            type="button"
            className={styles.back__button}
            onClick={() => window.history.back()}
          >
            <FaArrowLeft size={24} />
          </button>
        </div>
      </Container>

      <main className={styles.container}>{renderStep()}</main>

      <Toast />
    </>
  );
}

export { OrderProFormTemplate };

import { type Method } from 'axios';
import { type IncomingHttpHeaders } from 'http';

import { handleErrorResponse } from '../error/handleErrorResponse';

import { axiosInstance } from '../utils/axiosInstance';
import { applyHeaders } from '../utils/api/applyHeaders';
import { applyCustomHeaders } from '../utils/server/applyCustomHeaders';

const ERROR_SERVICE_NAME_PREFIX = '[BFF-SERVICE]';

interface IBffService<TData> {
  path: string;
  method: Method;
  refresh?: boolean;
  data?: TData | null;
  customHeaders?: Record<string, string> | IncomingHttpHeaders;
  params?: Record<string, string>;
  useCache?: boolean;
  version?: number;
}

interface IRequestOptions<TData> {
  headers: Record<string, string> | IncomingHttpHeaders;
  url: string;
  method: Method;
  data?: TData;
}

interface IBffResponse<TResponse> {
  data: TResponse;
}

async function bffService<TData = unknown, TResponse = unknown>({
  path,
  method,
  refresh,
  customHeaders = {},
  data = null,
  params = null,
  useCache = true,
  version = 1,
}: IBffService<TData>): Promise<IBffResponse<TResponse>> {
  const { headers: bffHeaders } = applyHeaders();
  let completePath = path;

  if (params) {
    const query = `?${new URLSearchParams(params).toString()}`;
    completePath = `${path}${query}`;
  }

  const isGetRequestMethod = method === 'GET';

  const shouldUseCacheLayer = isGetRequestMethod && useCache;

  let url = `${process.env.BACKEND_FOR_FRONTEND_URL}/v${version}/${completePath}`;

  if (shouldUseCacheLayer) {
    url = `${
      process.env.FRONTEND_REQUEST_LAYER_URL
    }/api/v2/proxy/backend-for-frontend?path=${encodeURIComponent(
      `api/v${version}/${completePath}`
    )}`;
  }

  if (refresh) {
    url = `${url}&refresh=true`;
  }

  const dataIsNotEmpty = data !== null;

  const headers = {
    ...bffHeaders,
  };

  applyCustomHeaders({
    headers,
    customHeaders,
    keys: ['x-request-id', 'x-frl-key', 'Authorization'],
  });

  let requestOptions: IRequestOptions<TData> = {
    headers,
    url,
    method,
  };

  if (dataIsNotEmpty) {
    requestOptions = {
      ...requestOptions,
      data,
    };
  }

  try {
    const response = await axiosInstance<TResponse>(requestOptions);

    return {
      data: response.data,
    };
  } catch (error) {
    throw handleErrorResponse({
      error,
      prefix: ERROR_SERVICE_NAME_PREFIX,
    });
  }
}

export { bffService, ERROR_SERVICE_NAME_PREFIX };

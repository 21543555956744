import meta from './meta.json';
import emptyOrders from './emptyOrders.json';
import prosNotFoundOrder from './prosNotFoundOrder.json';
import blockedOrder from './blockedOrder.json';

export const orders = {
  meta,
  emptyOrders,
  prosNotFoundOrder,
  blockedOrder,
};

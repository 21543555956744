export const DEFAULT_LANGUAGE = 'pt-BR';
export const ACTIVE_VERSION = '7';

export interface IApplyHeaders {
  setHeader: (key: string, value: string) => void;
}

export function applyHeaders<TApplyHeaders extends IApplyHeaders>(
  res?: TApplyHeaders,
  version = ACTIVE_VERSION
) {
  if (res) {
    res.setHeader('Content-Type', 'application/json');
  }

  const headers = {
    'Accept-Language': DEFAULT_LANGUAGE,
    'Accept-Version': version,
    'Content-Type': 'application/json',
    Accept: '*/*',
  };

  return {
    headers,
  };
}

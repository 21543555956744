import hero from './hero.json';
import relatedCategories from './relatedCategories.json';

import { faqCallToAction, meta, mostSearchedServices } from '../shared/request';

export const postRequest = {
  meta,
  hero,
  relatedCategories,
  mostSearchedServices,
  faqCallToAction,
};

import averagePrices from './averagePrices.json';
import common from './common.json';
import ctaPro from './ctaPro.json';
import footerContent from './footerContent.json';
import headerContent from './headerContent.json';
import heroInputContent from './heroInputContent.json';
import mainServices from './mainServices.json';
import ratings from './ratings.json';
import rootSlugs from './rootSlugs.json';
import routes from './routes.json';
import similarRequests from './similarRequests.json';
import proList from './proList.json';
import relatedLinks from './relatedLinks.json';

export {
  averagePrices,
  common,
  ctaPro,
  footerContent,
  headerContent,
  heroInputContent,
  mainServices,
  ratings,
  rootSlugs,
  routes,
  similarRequests,
  proList,
  relatedLinks,
};

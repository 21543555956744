import { handleErrorResponse } from '../error/handleErrorResponse';

import { axiosInstance } from '../utils/axiosInstance';
import { applyCustomHeaders } from '../utils/server/applyCustomHeaders';

const ERROR_SERVICE_NAME_PREFIX = '[CMS-Frontend]';

export type TCMSItem<T> = {
  id: number;
  attributes: T;
};

interface ICMSResponse<T> {
  data: TCMSItem<T>[];
  meta: {
    pagination?: {
      page: number;
      pageSize: number;
      pageCount: number;
      total: number;
    };
  };
}

export const cmsService = async <T>(
  path: string,
  query?: unknown,
  customHeaders?: Record<string, string>
): Promise<TCMSItem<T>[]> => {
  try {
    const headers = {};

    applyCustomHeaders({ headers, customHeaders, keys: ['x-request-id'] });

    const { data } = await axiosInstance<ICMSResponse<T>>({
      baseURL: process.env.CMS_ENDPOINT,
      url: path,
      params: query,
      headers,
    });

    return data.data;
  } catch (error) {
    handleErrorResponse({
      error,
      prefix: ERROR_SERVICE_NAME_PREFIX,
    });

    return null;
  }
};

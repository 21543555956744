import { useState } from 'react';

import { Avatar, Button, Col, Container, Row, Typography } from 'darrius';

import { Dialog } from '../../../../../../components';

import { type TOrderDetailLeads } from '../../../../../../modules/orderDetails/transformations/@interfaces';

import styles from './OrderMessageDialog.module.scss';
import { type AxiosRequestConfig } from 'axios';
import { axiosInstance } from '../../../../../../utils/axiosInstance';
import { navigate, triggerEvent } from '../../../../../../utils';
import { fetchSession } from '../../../../../../utils/fetchSession';
import Toast, {
  showToast,
} from '../../../../../../components/atoms/Toast/Toast';
import { notifyErrorClient } from '../../../../../../services/notifyError/client';
import { errorPrefix } from '../../../../../../utils/errorPrefix';

interface IOrderMessageDialogProps {
  pro: TOrderDetailLeads;
  orderId: string;
  handleToggleDialog: () => void;
  initialMessage: string;
  chatUrl: string;
  avatar: string;
  uuid: string;
}

function OrderMessageDialog({
  pro,
  orderId,
  handleToggleDialog,
  initialMessage,
  avatar,
  uuid,
}: IOrderMessageDialogProps) {
  const [isContactDeclined, setIsContactDeclined] = useState(false);

  function handleToggleDeclineContact() {
    triggerEvent('chat-cli__refuse-negotiation', {
      requestUuid: uuid,
      profileId: pro.id,
    });

    setIsContactDeclined((prev) => !prev);
  }

  const handleTriggerDecideLater = (type: 'rejection' | 'choice') => {
    triggerEvent('chat-cli__decide-later', {
      requestUuid: uuid,
      profileId: pro.id,
      type,
    });
  };

  const handleTriggerDecideLaterConfirmation = () => {
    triggerEvent('chat-cli__refuse-negotiation-confirmation', {
      requestUuid: uuid,
      profileId: pro.id,
    });
    navigate(`/meus-pedidos/${orderId}/profissional/${pro.leadId}`);
  };

  function handleCloseDialog() {
    setIsContactDeclined(false);

    handleToggleDialog();
  }

  const handleSubmit = async () => {
    const session = fetchSession();
    triggerEvent('chat-cli__accept-negotiation', {
      requestUuid: uuid,
      profileId: pro.id,
    });

    if (!session) {
      return;
    }

    const headers = {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      'Accept-Version': 7,
      Authorization: session.authorization,
    };

    try {
      const requestOptions: AxiosRequestConfig = {
        headers,
        url: `${pro.acceptedConversation}`,
        method: 'POST',
        data: {
          lead_id: pro.id,
        },
      };

      await axiosInstance(requestOptions, false).then((response: any) => {
        navigate(`/mensagens/${response.data.chat_url}`);
      });
    } catch (error) {
      notifyErrorClient(errorPrefix('[ORDER-DETAILS-DIALOG]', error));
      showToast({
        type: 'error',
        message: 'Erro ao aceitar a negociação. Por favor, tente novamente.',
      });
    }
  };

  function renderDialogContent() {
    if (!isContactDeclined) {
      return (
        <Row>
          <Col className={styles.content}>
            <Dialog.Title asChild>
              <Typography.Subtitle size="l" as="h1">
                Você recebeu uma mensagem!
              </Typography.Subtitle>
            </Dialog.Title>

            <Dialog.Description asChild>
              <Typography.Text as="p">
                O profissional tem interesse no serviço e iniciou uma conversa
                no chat. Leia a mensagem abaixo e decida se continua a
                negociação.
              </Typography.Text>
            </Dialog.Description>

            <Row className={styles.user_image}>
              <Col className={styles.image}>
                <Col className={styles.image__name}>
                  <Avatar
                    src={avatar}
                    alt={pro.name}
                    size={48}
                    fallback={{
                      name: pro.name,
                      variant: 'byName',
                    }}
                  />

                  <Typography.Text as="span">{pro.name}</Typography.Text>
                </Col>

                <Col className={styles.image__ballon}>
                  <Typography.Text as="span">{initialMessage}</Typography.Text>
                </Col>
              </Col>
            </Row>

            <Button.Root size="small" fullWidth onClick={handleSubmit}>
              Aceitar e iniciar negociação
            </Button.Root>
            <Button.Root
              size="small"
              variation="secondary"
              fullWidth
              onClick={handleToggleDeclineContact}
            >
              Recusar Negociação
            </Button.Root>
            <Button.Root
              size="small"
              variation="clear"
              fullWidth
              onClick={() => {
                handleToggleDialog();
                handleTriggerDecideLater('rejection');
              }}
            >
              Decidir depois
            </Button.Root>
          </Col>
        </Row>
      );
    }

    return (
      <Row>
        <Col className={styles.content}>
          <Dialog.Title asChild>
            <Typography.Subtitle size="l" as="h1">
              Tem certeza que deseja recusar o contato?
            </Typography.Subtitle>
          </Dialog.Title>

          <Dialog.Description asChild>
            <Typography.Text as="p">
              Ao continuar, este profissional sairá da sua lista e você não
              poderá mais prosseguir com a negociação.
            </Typography.Text>
          </Dialog.Description>

          <Button.Root
            size="small"
            fullWidth
            onClick={() => {
              handleCloseDialog();
              handleTriggerDecideLater('choice');
            }}
          >
            Decidir depois
          </Button.Root>
          <Button.Root
            onClick={handleTriggerDecideLaterConfirmation}
            size="small"
            variation="secondary"
            fullWidth
          >
            Recusar contato
          </Button.Root>
        </Col>
      </Row>
    );
  }

  return (
    <Dialog.Portal>
      <Dialog.Content className={styles.dialog__content}>
        <Container>
          {renderDialogContent()}
          <Toast />
        </Container>
      </Dialog.Content>
    </Dialog.Portal>
  );
}

export { OrderMessageDialog };

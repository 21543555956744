import config from './config.json';
import heroSection from './heroSection.json';
import bannerFull from './bannerFull.json';
import categoryCards from './categoryCards.json';
import frequentRequests from './frequentRequests.json';
import structuredData from './structuredData.json';
import servicesCategories from './servicesCategories.json';
import tipsBeforeHiring from './tipsBeforeHiring.json';
import bottomLinks from './bottomLinks.json';
import recommendations from './recommendations.json';
import whatIsGetNinjas from './whatIsGetNinjas.json';
import statistics from './statistics.json';
import register from './register.json';
import howItWorks from './howItWorks.json';
import about from './about.json';
import ourValues from './ourValues.json';

export const home = {
  config,
  heroSection,
  bannerFull,
  categoryCards,
  structuredData,
  servicesCategories,
  bottomLinks,
  recommendations,
  frequentRequests,
  tipsBeforeHiring,
  whatIsGetNinjas,
  statistics,
  register,
  howItWorks,
  about,
  ourValues,
};
